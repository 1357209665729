
import Vue from "vue";
import { BIcon } from "bootstrap-vue";
import { mapGetters } from "vuex";
import Notifications from "@/components/Notifications.vue";
import Socket from "@/services/Socket";
import SearchResults from "@/components/SearchResults.vue";

export default Vue.extend({
  name: "HeaderTwo",
  components: {
    Notifications,
    BIcon,
    SearchResults
  },
  props: {
    isRegister: Boolean
  },
  data() {
    return {
      notification: {},
      // Menu buttons
      menu: [
        {
          name: (this as any).$t("setting.headerTwoMenuItemFeed"),
          icon: "feed",
          action: () => {
            const path = "/news-feed";
            if (this.$route.path !== path) this.$router.push(path);
          }
        },
        {
          name: (this as any).$t("setting.headerTwoMenuItemMessages"),
          icon: "chat",
          messagesAmount: 2,
          action: () => {
            const path = "/conversations";
            if (this.$route.path !== path) this.$router.push(path);
          }
        },
        {
          name: (this as any).$t("setting.headerTwoMenuItemAgenda"),
          icon: "calendar",
          action: () => {
            const path = "/agenda";
            if (this.$route.path !== path) this.$router.push(path);
          }
        }
        // {
        //   name: "Discover",
        //   icon: "users",
        //   action: () => {
        //     const path = "/discover/mentors";
        //     if (this.$route.path !== path) this.$router.push(path);
        //   }
        // }
      ],
      // Profile dropdown menu items
      profileDropdownMenu: [
        {
          name: (this as any).$t("setting.headerTwoMenuItemProfile"),
          icon: "profile",
          action: () => {
            const path = "/profile";
            if (this.$route.path !== path) this.$router.push(path);
          }
        },
        {
          name: (this as any).$t("setting.headerTwoMenuItemWallet"),
          icon: "wallet",
          text: this.$store.getters.wallet.total,
          action: () => {
            const path = "/wallet";
            if (this.$route.path !== path) this.$router.push(path);
          }
        },
        {
          name: (this as any).$t("setting.headerTwoMenuItemSettings"),
          // name: "Admin Panel",
          icon: "settings",
          action: () => {
            const path = "/settings";
            if (this.$route.path !== path) this.$router.push(path);
          }
        },
        {
          name: (this as any).$t("setting.headerTwoMenuItemLogOut"),
          icon: "logout",
          action: () =>
            this.$store
              .dispatch("auth/LOGOUT_USER")
              .then(() => this.$router.push("/"))
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      isAuthorized: "auth/getAuthorize",
      profile: "profile/getProfile",
      settings: "helper/getSetting"
    }),
    getLogo(): any {
      if (this.settings.logo) {
        return this.settings.logo;
      } else {
        return require("@/assets/icons/mentorice-logo-large.svg");
      }
    },
    getHeaderStyle(): string | null {
      if (this.settings.global_header_color)
        return `background: ${this.settings.global_header_color}`;

      return null;
    }
  },
  mounted(): void {
    this.$store.dispatch("profile/GET_PROFILE").then(() => {
      Socket().emit("online", this.profile.id);
      Socket().on("notification", (data: any) => {
        this.notification = data;
        this.$bvToast.show("notification-toast");
        this.$store.commit("profile/INCREASE_NOTIFICATION_COUNT");
        this.setNotifications(data);
      });
    });
    this.$store.dispatch("helper/GET_SETTING");
  },
  methods: {
    profileLink() {
      const path = "/profile";
      if (this.$route.path !== path) this.$router.push(path);
    },
    handleSearch() {
      console.log("Search button clicked!");
    },
    setNotifications(data: any): void {
      const notification = {
        created_at: "1 sec ago",
        read_at: null,
        data
      };
      if (this.$refs["notifications-dropdown"]) {
        //@ts-ignore
        this.$refs["notifications-dropdown"].newNotification(notification);
      }
    }
  }
});
